/* HD to Large screens (base font-size: 16px) */
@media (min-width: 1024px) {
  /* Heading Styles */
  .custom-h1-large {
    font-family: 'Poppins', sans-serif;
    font-size: 76px;
    font-weight: bold;
    line-height: 1.2; /* Suggested line-height */
  }

  .custom-h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 61px;
    font-weight: bold;
    line-height: 1.2; /* Suggested line-height */
  }

  .custom-h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 49px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 39px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 31px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h5 {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-h6 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    font-weight: medium;
    line-height: 1.4; /* Suggested line-height */
  }

  /* Body Styles */
  .custom-body-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  /* ...other styles for HD to Large screens... */
}

/* Large to Medium size screens (base font-size: 15px) */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Heading Styles */
  .custom-h1-large {
    font-family: 'Poppins', sans-serif;
    font-size: 70px;
    font-weight: bold;
    line-height: 1.2; /* Suggested line-height */
  }

  .custom-h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 57px;
    font-weight: bold;
    line-height: 1.2; /* Suggested line-height */
  }

  .custom-h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 37px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 29px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h5 {
    font-family: 'Poppins', sans-serif;
    font-size: 23px;
    font-weight: bold;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-h6 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    font-weight: medium;
    line-height: 1.4; /* Suggested line-height */
  }

  /* Body Styles */
  .custom-body-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  /* ...other styles for Large to Medium screens... */
}

/* Medium to Small size screens (base font-size: 14px) */
@media (max-width: 767px) {
  /* Heading Styles */
  .custom-h1-large {
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    font-weight: bold;
    line-height: 1.2; /* Suggested line-height */
  }

  .custom-h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.2; /* Suggested line-height */
  }

  .custom-h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.1; /* Suggested line-height */
  }

  .custom-h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 27px;
    font-weight: bold;
    line-height: 1.3; /* Suggested line-height */
  }

  .custom-h5 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-h6 {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: medium;
    line-height: 1.4; /* Suggested line-height */
  }

  /* Body Styles */
 

  /* ...other styles for Medium to Small screens... */
}

/* HD to Large screens (base font-size: 16px) */
@media (min-width: 1024px) {
  /* ... (Previous styles for headings are here) ... */

  /* Body Styles */
  .custom-body-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-large-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-regular {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-body-regular-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-extra-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  /* Label Text Styles */
  .custom-label-text-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-medium {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }
}

/* Large to Medium size screens (base font-size: 15px) */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Heading Styles */
  /* ... (Styles for headings for this viewport size) ... */

  /* Body Styles */
  .custom-body-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-large-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-regular {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-body-regular-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 15px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-extra-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  /* Label Text Styles */
  .custom-label-text-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-medium {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }
}

/* Medium to Small size screens (base font-size: 14px) */
@media (max-width: 767px) {
  /* Heading Styles */
  /* ... (Styles for headings for this viewport size) ... */

  /* Body Styles */
  .custom-body-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 17px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-regular {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-body-regular-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-extra-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  /* Label Text Styles */
  .custom-label-text-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 17px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-medium {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }
}
/* Small size screens (base font-size: 14px) */
@media (max-width: 767px) {
  /* Body Styles */
  .custom-body-large-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 17px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-regular {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4; /* Suggested line-height */
  }

  .custom-body-regular-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-small-bold {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    font-weight: medium;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-body-extra-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  /* Label Text Styles */
  .custom-label-text-large {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 17px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-medium {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }

  .custom-label-text-small {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.6; /* Suggested line-height */
  }
}


